<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/team/img-bg.png" class="img"/>
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">{{ TeamIntroduction.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ TeamIntroduction.alias }}</div>
      </div>
      <div class="bom" v-for="(item,index) in Introduction" :key="item.index">
        <div class="left-item" v-if="index%2 !== 1">
          <div class="item"  :class="{ bounceInLeft: show2 }" >
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.content }}</div>
              </div>
            </div>
            <img :src="item.picture" class="img"/>
          </div>
        </div>
        <div class="right-item" v-if="index%2 !== 0">
          <div class="item" :class="{ bounceInRight: show2 }" >
            <img :src="item.picture" class="img"/>
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="top">
        <div class="title">{{ businessTeam.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ businessTeam.alias }}</div>
      </div>
      <div class="bom">
        <div class="title">
          <div
              class="item"
              :class="{ active: index == curIndex }"
              v-for="(item, index) in business"
              :key="index"
              @click="tabbtn(index)"
          >
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="content">
          <div class="box">
            <Carousel v-model="curIndex" loop arrow="never" dots="none">
              <CarouselItem v-for="(item, index) in business" :key="index">
                <div class="item-box">
                  <img class="img" :src="item.picture" />
                  <div class="text">{{ item.content }}</div>
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="content2">
      <div class="top">
        <div class="title">{{ Corevalues.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Corevalues.alias }}</div>
      </div>
      <div class="bom">
        <div class="item" v-for="(item, index) in values" :key="index">
          <img :src="item.picture" class="icon"/>
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">{{ Corevalues.title }}</div>
        <div class="line"></div>
        <div class="title1">{{ Corevalues.alias }}</div>
      </div>
      <div class="bom">
        <div class="bom-box">
          <div class="item" v-for="(item, index) in Serviceleft" :key="index">
            <div class="left">
              <img :src="item.picture" class="img"/>
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="bom-box">
          <div class="item" v-for="(item, index) in Serviceright" :key="index">
            <div class="left">
              <img :src="item.picture" class="img"/>
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <floatBar></floatBar>-->
  </div>
</template>

<script>
// import floatBar from "../../components/FloatBar";
import {get, post} from '../../utils/http.js'
import config from '../../config/index'

export default {
  metaInfo: {
    title: "浙江骐骥软软件科技",
    meta: [
      {
        name: "Team",
        content: "服务团队",
      },
    ],
  },
  props: {},
  data() {
    return {
      curIndex:0,
      show1: false,
      show2: false,
      left: [],
      right: [],
      Serviceleft: [],
      Serviceright: [],
      Introduction: [],
      TeamIntroduction: {},
      business:[],
      businessTeam:[],
      values: [],
      Corevalues: {},
      Service: [],
      Servicepurpose: {},
      curIndex1: null,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.toTop();
    this.lode();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj"
      }
      let url = "/login"
      post(url, data).then(res => {
        localStorage.setItem("token", res.token);
        this.team()
        this.value()
        this.purpose()
        this.teamBro()
      })
    },
    team() {
      let teamurl = "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=5"
      get(teamurl, null).then(res => {
        this.TeamIntroduction = res.companyClassify
        this.Introduction = res.companyArticles
        for (let i = 0; i < this.Introduction.length; i++) {
          this.Introduction[i].picture = config.imageUrl + this.Introduction[i].picture
        }
      })
    },
    teamBro() {
      let teamurl = "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=8"
      get(teamurl, null).then(res => {
        this.businessTeam = res.companyClassify
        this.business = res.companyArticles
        for (let i = 0; i < this.business.length; i++) {
          this.business[i].picture = config.imageUrl + this.business[i].picture
        }
      })
    },
    value() {
      let Coreurl = "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=6"
      get(Coreurl, null).then(res => {
        this.Corevalues = res.companyClassify
        this.values = res.companyArticles
        for (let i = 0; i < this.values.length; i++) {
          this.values[i].picture = config.imageUrl + this.values[i].picture
        }
      })
    },
    purpose() {
      let purposeurl = "/portal/article/listByCompanyIdAndClassifyId?companyId=6&classifyId=7"
      get(purposeurl, null).then(res => {
        this.Servicepurpose = res.companyClassify
        this.Service = res.companyArticles
        for (let i = 0; i < this.Service.length; i++) {
          this.Service[i].picture = config.imageUrl + this.Service[i].picture
        }
        this.Serviceleft = this.Service.slice(0, 3);
        this.Serviceright = this.Service.slice(3, this.Service.length);
      })
    },
    tabbtn(i) {
      this.curIndex = i;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      // console.log(scrollTop);
      if (scrollTop >= 200) {
        this.show1 = true;
      }
      if (scrollTop >= 850) {
        this.show2 = true;
      }

    },

  },

};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;

  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }

    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}

.top {
  text-align: center;

  .title {
    color: #000028;
    font-size: 24px;
  }

  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }

  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content4 {
  width: 100%;
  padding-top: 70px;
  .bom {
    margin-top: 60px;
    .title {
      display: flex;
      justify-content: space-around;
      margin-left: 160px;
      margin-right: 160px;
      margin-bottom: 33px;
      .item {
        width: 180px;
        background: #f4f4f4;
        height: 48px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        border-radius: 24px;
        color: #6f6f6f;
        font-size: 16px;
      }
      .active {
        background: #ef8200;
        .text {
          color: #fff;
        }
      }
    }
    .content {
      width: 100%;
      height: 500px;
      // background-color: #ef8200;
      background-image: url("../../assets/img/business/content-img.png");
      background-size: 100%;
      background-repeat: no-repeat;
      .box {
        width: 980px;
        height: 500px;
        margin: 0 auto;
        // background: #ef8200;
        .item-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .img {
            width: 120px;
            height: 120px;
          }
          .text {
            font-size: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            width: 630px;
            height: 500px;
            text-align: justify;
          }
        }
      }
    }
  }
}

.content1 {
  margin-top: 70px;

    .bom {
      width: 1600px;
      margin: 0 auto 10px;
      border-radius: 10px;
      display: flex;

      .left-item {
        .item {
          width: 1600px;
          height: 327px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            width: 800px;
            height: 327px;
            border-radius: 10px;
            display: inline-block;

          }

          .textbox {
            width: 800px;
            height: 215px;
            display: inline-block;


            .box {
              width: 670px;
              margin-left: 50px;

              .title {
                color: #000028;
                font-weight: 500;
                font-size: 20px;
              }

              .line {
                width: 50px;
                height: 2px;
                background: #ef8200;
                margin-top: 10px;
                margin-bottom: 28px;
              }

              .text {
                color: #000014;
                font-size: 16px;
              }
            }
          }
        }
      }

      .right-item {
        .item {
          width: 1600px;
          height: 327px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .img {
            width: 800px;
            height: 327px;
            border-radius: 10px;
            display: inline-block;

          }

          .textbox {
            width: 800px;
            height: 215px;
            display: inline-block;


            .box {
              width: 670px;
              margin-left: 50px;

              .title {
                color: #000028;
                font-weight: 500;
                font-size: 20px;
              }

              .line {
                width: 50px;
                height: 2px;
                background: #ef8200;
                margin-top: 10px;
                margin-bottom: 28px;
              }

              .text {
                color: #000014;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
.content2 {
  width: 100%;
  height: 470px;
  background: #f0f5fa;

  .top {
    text-align: center;

    .title {
      color: #000028;
      font-size: 24px;
    }

    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }

    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }

  padding-top: 30px;

  .bom {
    height: 380px;
    margin: 0 auto;
    margin-left: 350px;
    margin-right: 350px;
    margin-top: 60px;
    display: flex;
    justify-content: space-around;

    .item {
      width: 216px;
      height: 250px;
      position: relative;
      text-align: center;

      .icon {
        width: 100px;
        height: 100px;
      }

      .title {
        font-size: 20px;
        margin-top: 40px;
        color: #000028;
        font-weight: 800;
      }

      .text {
        font-size: 16px;
        margin-top: 18px;
        color: #000014;
        text-align: left;
      }
    }
  }
}

.content3 {
  height: 483px;
  padding-top: 70px;
  width: 100%;

  .bom {
    margin: 0 auto;
    margin-top: 60px;

    .bom-box {
      display: flex;
      justify-content: space-around;
      margin-left: 350px;
      margin-right: 350px;

      .item {
        width: 250px;
        height: 76px;
        border-radius: 10px;
        margin-bottom: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .img {
          width: 60px;
          height: 60px;
          position: relative;
          top: 15px;
        }

        .right {
          margin-left: 18px;

          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000028;
          }

          .line {
            width: 44px;
            height: 2px;
            background: #ef8200;
            margin-top: 6px;
            margin-bottom: 12px;
          }

          .text {
            font-size: 14px;
            font-weight: 400;
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
</style>